export const projectData = [
  {
    description:
      'This app allows users to search movies, play movie trivia, store movies to a watch list, and just for fun -- get some dad jokes.',
    technologies:
      'I used React, TypeScript, CSS, and a variety of external APIs to create this app.',
    otherNotes:
      'I built this application on my own and am comfortable with all of the functionality.',
    websiteLink: 'https://movie-app-gamma.vercel.app/',
    githubLink: 'https://github.com/Ryan-Solomon/movie-app',
    demo: 'https://youtu.be/Xvmp46JYyHA',
  },
  {
    description:
      'This app allows users to play songs, search songs, and view songs in their library.',
    technologies:
      'I used React Native, TypeScript, Styled Components, AWS, and GraphQL to build this app.',
    otherNotes:
      'I built this application on my own and am comfortable with all of the functionality.',
    websiteLink: 'https://youtu.be/larjuiLvtsk',
    githubLink: 'https://github.com/Ryan-Solomon/movie-app',
  },
  {
    description:
      'This app allows users to search listings, filter listings based on guests, view a map with listings, save listings, and sign in and out.',
    technologies:
      'I used React Native, TypeScript, Styled Components, AWS, and GraphQL to build this app.',
    otherNotes:
      'I built this application on my own and am comfortable with all of the functionality.',
    websiteLink: 'https://youtu.be/d6kkpUnb17I',
    githubLink: 'https://github.com/Ryan-Solomon/movie-app',
  },
  {
    description:
      "This is an Ecommerce app built up from scratch. Yes, my mom's name is Karen. She just retired, and I think she needs a hobby. Please note, the hosting provider I used will have a 'cold start' -- meaning it will take this app several seconds to initially load.",
    technologies:
      'I used React, JavaScript, Redux, React Bootstrap, MongoDB, NodeJs, and Express to create this app.',
    otherNotes:
      "I used an ecommerce tutorial to build this app, but I'm comfortable with most of the functionality of this app and was able to customize it to make it my own.",
    websiteLink: 'https://karenscrafts.herokuapp.com/',
    githubLink: 'https://github.com/Ryan-Solomon/karens-crafts',
  },
  {
    description:
      'This app allows users to view vehicles, see specs about vehicle, and fill in details to order their vehicle.',
    technologies:
      'I used React, TypeScript, SCSS, and Framer Motion to create this app.',
    otherNotes:
      'I built this application on my own and am comfortable with all of the functionality.',
    websiteLink: 'https://tesla-one.vercel.app/',
    githubLink: 'https://github.com/Ryan-Solomon/tesla',
  },
  {
    description:
      'This is a music player app. It performs simple functionality to play songs from a library, skip songs, etc.',
    technologies: 'I used React, JavaScript, and SCSS to build this site.',
    otherNotes:
      'I built this application on my own and am comfortable with all of the functionality.',
    websiteLink: 'https://wavesmusicplayer.netlify.app/',
    githubLink: 'https://github.com/Ryan-Solomon/react-music-player',
  },
  {
    description:
      "Well, considering you're using this app now, I think you already get the gist of what it is.",
    technologies:
      'I used React, JavaScript, Styled Components, SCSS, and an animation library called Framer Motion to build this site.',
    otherNotes:
      'I built this application on my own and am comfortable with all of the functionality.',
    websiteLink: '',
    githubLink: 'https://github.com/Ryan-Solomon/ryansolomon-io',
  },
];
