import React from 'react';
//Animations
import { motion } from 'framer-motion';
import { titleAnim } from '../animation';
import styled from 'styled-components';
import ContactForm from '../components/ContactForm';

const ContactUs = () => {
  return (
    <ContactStyle
      exit='exit'
      // variants={pageAnimation}
      initial='hidden'
      animate='show'
      style={{ background: '#1b1b1b' }}
    >
      <Title>
        <Hide>
          <motion.h2 variants={titleAnim}>Get In Touch</motion.h2>
        </Hide>
      </Title>
      <div>
        <Hide>
          <Social variants={titleAnim}>
            <ContactForm />
          </Social>
        </Hide>
      </div>
    </ContactStyle>
  );
};

const ContactStyle = styled(motion.div)`
  padding: 5rem 10rem;
  color: #fff;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1500px) {
    padding: 2rem;
    font-size: 1rem;
  }
`;
const Title = styled.div`
  margin-bottom: 4rem;
  color: #fff;
  @media (max-width: 1500px) {
    margin-top: 5rem;
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;
const Social = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 2rem;
  }
`;

export default ContactUs;
